import { WppActionButton, WppIconAttach, WppTooltip } from '@platform-ui-kit/components-library-react'
import { useRef } from 'react'

import { tooltipConfig } from 'utils/tooltipConfig'

import styles from './FileUpload.module.scss'

export interface FileUploadProps {
  existingFileUrl?: string | null
  existingFileName?: string | null
  handleFileUploadChange: (event: any) => void
  wppFile?: File[] | undefined
  disabled?: boolean
}

export const FileUpload = ({ handleFileUploadChange, disabled = false }: FileUploadProps) => {
  const fileInputRef = useRef<any>()

  return (
    <div className={styles.container}>
      {disabled ? (
        <WppTooltip text="Files can be uploaded only in empty conversation" config={tooltipConfig}>
          <WppActionButton onClick={() => fileInputRef.current?.click()} color="#4D5358" disabled={disabled}>
            <WppIconAttach slot="icon-start" color="#4D5358" />
          </WppActionButton>
        </WppTooltip>
      ) : (
        <WppActionButton onClick={() => fileInputRef.current?.click()} color="#4D5358" disabled={disabled}>
          <WppIconAttach slot="icon-start" color="#4D5358" />
        </WppActionButton>
      )}

      <input onChange={handleFileUploadChange} multiple ref={fileInputRef} type="file" hidden />
    </div>
  )
}
